@use '../includes' as *;

$live-event-hero-bp: $sm;

.live_event_hero {
  position: relative;
  width: 100%;
  min-width: 33vw;
  aspect-ratio: 16 / 9;
  margin-block-end: var(--spacing-block);
  padding-inline: var(--spacing-inline);
  background-color: $midnight;

  @include breakpoint($live-event-hero-bp) {
    margin-block-end: calc((var(--spacing-block) + 7vw) * -1);
    padding-inline: unset;
  }

  @include breakpoint($xxl) {
    margin-block-end: calc((var(--spacing-block) + 15rem) * -1);
  }
}

.live_event_hero_background {
  button {
    inset-block-start: 0;

    @include breakpoint($live-event-hero-bp) {
      inset-block-start: -10%;
    }
  }

  @include breakpoint($below-sm) {
    overflow: hidden;
    border-radius: $radius-large $radius-large 0 0;
  }
}

.live_event_hero_overlay {
  display: flex;
  position: relative;
  inset: 0;
  flex-direction: column;
  justify-content: center;
  padding-block: $g20;
  padding-inline: var(--spacing-inline);
  border-radius: 0 0 $radius-large $radius-large;
  background-color: $navy-blue;
  pointer-events: none;

  button,
  a {
    pointer-events: auto;
  }

  @include breakpoint($live-event-hero-bp) {
    position: absolute;
    // Accounting for a small 1px gap between background and gradient
    inset-block-end: -1px;
    justify-content: flex-start;
    padding-block-start: $g32;
    border-radius: unset;
    background-color: transparent;
    background-image: linear-gradient(
        to top,
        $midnight 5%,
        rgba($midnight, 0.36) 22%,
        rgba($midnight, 0) 38%
      ),
      linear-gradient(
        to right,
        $midnight 3%,
        rgba($midnight, 0.36) 30%,
        rgba($midnight, 0) 50%
      );
  }

  @include breakpoint($md) {
    padding-block-start: 12%;
  }
}

.live_event_show_logo {
  width: clamp(140px, 20%, 240px);
  margin-block-end: $g16;
}

.live_event_title {
  @include h2;

  margin-block-end: $g8;
  line-height: 1.5;

  @include breakpoint($xs-only) {
    margin-block-end: $g12;
  }

  @include breakpoint($live-event-hero-bp) {
    width: 35%;
    margin-block-end: $g16;
  }
}

.live_event_description {
  @include body-copy-3;

  @include breakpoint($live-event-hero-bp) {
    width: 30%;

    @include body-copy-1;

    font-size: clamp($f14, 1.1714rem + 0.2976vw, $f16);
  }

  margin-block-end: var(--hero-gutter);
}

.live_event_hero_overlay_buttons {
  display: flex;
  position: relative;
  gap: $g16;

  button,
  a {
    pointer-events: auto;
  }
}
